body {
	padding: 0px;
	margin: 0px;
	position: relative;
	min-height: 100vh;
	min-width: 100vw;
}

a {
	color: black;
}


p {
	line-height: 1.2em;
}

p.small {
	font-size: 11px;
	margin: 3px 0px;
}

p.center {
	text-align: center;
}